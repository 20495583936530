import React, { useState } from "react";
import { IItem } from "../../constants/coffees";

interface IProps {
  item: IItem;
  setItems: (React.Dispatch<React.SetStateAction<IItem[]>>);
  setEditModalOpen: (React.Dispatch<React.SetStateAction<boolean>>);
}

const EditItemForm = ({ item, setItems, setEditModalOpen }: IProps) => {
  const [name, setName] = useState(item.name);
  const [price, setPrice] = useState(item.price.toString());
  const [infoMessage, setInfoMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/items/${item.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, price: parseFloat(price) }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const updatedItem = await response.json();
      setItems((prev) => prev.map((i) => (i.id === item.id ? updatedItem : i)));

      setLoading(false);
      setEditModalOpen(false);
    } catch (error) {
      setInfoMessage("Chyba pri úprave itemu");
      setLoading(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-center">Upraviť produkt</h2>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Názov:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Cena:</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white py-2 rounded-lg">
          {loading ? "Ukladám..." : "Upraviť"}
        </button>
        <p className="text-red-500">{infoMessage}</p>
      </form>
    </div>
  );
};

export default EditItemForm;
