import React from "react";
import { IItem, IOption } from "../../constants/coffees";
import CheckBox from "../CheckBox/CheckBox";
import NumberPicker from "../NumberPicker/NumberPicker";

interface IProps extends IItem {
  addToOrder: (item: IItem) => void;
  closeModal: () => void;
}

const ProductModal = ({
  id,
  name,
  className,
  image_path,
  options,
  price,
  position,
  addToOrder,
  closeModal,
}: IProps) => {
  const [item, setItem] = React.useState<IItem>({
    id: id,
    name: name,
    image_path: image_path,
    screen: true,
    className: className,
    price: price,
    position: position,
    options: [],
    count: 1,
  });

  const sortOptions = () => {
    const sortedOptions = item.options?.sort((a, b) =>
      a.short_name.localeCompare(b.short_name)
    );
    return sortedOptions;
  };

  const isOptionInItemOptions = (option: IOption) =>
    item.options?.indexOf(option) !== -1;

  const onOptionChange = (option: IOption) => {
    setItem((currItem) => {
      return {
        ...currItem,
        options:
          currItem.options?.indexOf(option) === -1
            ? [...currItem.options, option]
            : currItem.options?.filter((_option) => _option.id !== option.id),
      };
    });
  };

  const onCountChange = (count: number) => {
    setItem((prevState) => {
      return {
        ...prevState,
        count: count,
      };
    });
  };

  return (
    <div className="flex relative w-full">
      <div className="flex items-center">
        <img
          src={image_path}
          alt={name}
          width={200}
          height={200}
          className=" "
        />
      </div>
      <div className="pl-8 pt-2">
        <div className="flex font-bold text-2xl">{name}</div>
        {options?.map((option) => (
          <CheckBox
            key={option.id}
            title={option.name}
            onChange={() => onOptionChange(option)}
            checked={isOptionInItemOptions(option)}
          />
        ))}
        <NumberPicker count={item.count!} onCountChange={onCountChange} />
        <div className="flex w-64 h-20 items-end justify-end">
          <button
            onClick={() => {
              // Sort Options before inserting to order
              addToOrder({ ...item, options: sortOptions() });
              closeModal();
            }}
            className="bg-lightyellow rounded-md py-2 px-5 text-md font-bold"
          >
            Pridať
          </button>
        </div>
      </div>
    </div>
  );
};
export default ProductModal;