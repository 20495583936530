import React from 'react';
import loadingGIF from '../../images/loadingGIF.gif';

interface IProps {
  item_id: number;
  onRemove: (id: number) => void;  // Callback to handle item removal in the parent component
}

const RemoveItemButton: React.FC<IProps> = ({ item_id, onRemove}) => {
  const [loading, setLoading] = React.useState(false);
  const handleRemove = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/items/${item_id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Call the onRemove callback to update the parent component
      onRemove(item_id);
      setLoading(false);
    } catch (error) {
      console.error('Error removing item:', error);
    }
  };

  return (
    <button
      onClick={handleRemove}
      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
    >
      {loading ? <img src={loadingGIF} alt="Loading..." className="h-5 w-5" /> : "odstrániť "} 
    </button>
  );
};

export default RemoveItemButton;
