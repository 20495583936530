import Modal from "react-modal";
import React, { useState, useEffect } from "react";
import Logo from "../components/Logo/Logo";
import { IItem } from "../constants/coffees";
import AddItemForm from "../components/AddItemForm/AddItemForm";
import EditItemForm from "../components/EditItemForm/EditItemForm";
import RemoveItemButton from "../components/RemoveItemButton/RemoveItemButton";
import fetchProducts from "../helpers/fetchItems";

Modal.setAppElement("#root");

const AdminPage = () => {
  const [items, setItems] = useState<IItem[]>([]);
  const [isFormModalOpen, setFormModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IItem | null>(null);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

  useEffect(() => {
    fetchProducts(setItems);
  }, []);

  function handleRemove(id: number): void {
    setItems(items.filter((item) => item.id !== id));
  }

  const closeAddItemModal = () => {
    setFormModalOpen(false);
  };

  // Hashing functions for colors
  const hashString = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  };

  const intToRGB = (hash: number) => {
    const r = (hash & 0xff0000) >> 16;
    const g = (hash & 0x00ff00) >> 8;
    const b = (hash & 0x0000ff) - 100;
    return `rgba(${r}, ${g}, ${b},0.2)`;
  };

  // Drag & Drop for Desktop
  const onDragStart = (e: React.DragEvent<HTMLTableRowElement>, index: number) => {
    e.dataTransfer.setData("text/plain", index.toString());
    setDraggingIndex(index);
  };

  const onDrop = (e: React.DragEvent<HTMLTableRowElement>, targetIndex: number) => {
    e.preventDefault();
    const sourceIndex = parseInt(e.dataTransfer.getData("text/plain"), 10);
    reorderItems(sourceIndex, targetIndex);
  };

  const allowDrop = (e: React.DragEvent<HTMLTableRowElement>) => {
    e.preventDefault();
  };

  // Touch Events for Mobile Drag & Drop
  const onTouchStart = (index: number) => {
    setDraggingIndex(index);
  };

  const onTouchMove = (e: React.TouchEvent<HTMLTableRowElement>) => {
    const touch = e.touches[0];
    const targetElement = document.elementFromPoint(touch.clientX, touch.clientY);
    if (!targetElement) return;

    const closestRow = targetElement.closest("tr");
    if (!closestRow || !closestRow.dataset.index) return;

    const targetIndex = parseInt(closestRow.dataset.index, 10);
    if (draggingIndex !== null && targetIndex !== draggingIndex) {
      reorderItems(draggingIndex, targetIndex);
      setDraggingIndex(targetIndex);
    }
  };

  const onTouchEnd = () => {
    setDraggingIndex(null);
  };

  // Function to reorder items
  const reorderItems = (sourceIndex: number, targetIndex: number) => {
    if (sourceIndex === targetIndex) return;
  
    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(sourceIndex, 1);
    updatedItems.splice(targetIndex, 0, movedItem);
  
    // Update the position attribute based on the new order
    const reorderedItems = updatedItems.map((item, index) => ({
      ...item,
      position: index, // Assigning new positions
    }));
  
    setItems(reorderedItems);
    SubmitPositions(reorderedItems);
  };
  

  const SubmitPositions = async (orderedItems: IItem[]) => {
    const orderedData = orderedItems.map((item, index) => ({
      id: item.id,
      position: index,
    }));
  
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/items/positions`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: orderedData }),
      });
    } catch (error) {
      console.error("Error saving position order:", error);
    }
  };

  return (
    <div className="h-screen max-h-screen bg-gray-800">
      <div className="pb-10">
        <Logo name="Správa UBaRe" />
      </div>
      <div className="flex justify-center">
        <button
          className="bg-lightblue text-lightyellow rounded-md py-2 px-5 mb-3 text-md font-bold active:scale-105 active:drop-shadow-xl"
          onClick={() => setFormModalOpen(true)}
        >
          Pridať produkt
        </button>
      </div>

      <Modal onRequestClose={closeAddItemModal} isOpen={isFormModalOpen}>
        <AddItemForm setItems={setItems} setFormModalOpen={setFormModalOpen} />
      </Modal>

      <Modal
                  onRequestClose={() => setEditModalOpen(false)}
                  isOpen={isEditModalOpen}
                  style={{
                    overlay: { background: "rgba(55, 55, 55, 0.8)", display: "flex", alignItems: "center", justifyContent: "center" },
                    content: { position: "relative", border: "1px solid #ccc", borderRadius: "0.3rem" },
                  }}
                >
                  {selectedItem && <EditItemForm item={selectedItem} setItems={setItems} setEditModalOpen={setEditModalOpen} />}
            </Modal>

      <div className="justify-center flex overflow-x-auto">
      <table className="text-white text-xl justify-center min-w-[50%]">

          <thead>
            <tr className="text-center">
              <th>Upravovanie</th>
              <th>Obrázok</th>
              <th>Názov</th>
              <th>Cena</th>
              <th>Mazanie</th>
            </tr>
          </thead>
          <tbody>
          {items
            .slice() // Create a shallow copy to avoid modifying the original array
            .sort((a, b) => a.position - b.position) // Sort items by the "position" attribute
            .map((item, index) => {
              const hash = hashString(item.name);
              const backgroundColor = intToRGB(hash);
              return (
                <tr
                  key={index}
                  data-index={index}
                  style={{ backgroundColor }}
                  draggable
                  onDragStart={(e) => onDragStart(e, index)}
                  onDragOver={allowDrop}
                  onDrop={(e) => onDrop(e, index)}
                  onTouchStart={() => onTouchStart(index)}
                  onTouchMove={onTouchMove}
                  onTouchEnd={onTouchEnd}
                  className="cursor-grab active:cursor-grabbing"
                >
                  <td className="text-center">
                    <button
                      className="bg-yellow-500 text-white px-3 py-1 rounded-lg hover:bg-yellow-700"
                      onClick={() => {
                        setSelectedItem(item);
                        setEditModalOpen(true);
                      }}
                    >
                      Upraviť
                    </button>
                  </td>
                  <td className="flex justify-center items-center">
                    <img className="w-[80px] h-[80px] sm:w-[100px] sm:h-[100px]" src={item.image_path} alt={item.name} />
                  </td>
                  <td className="text-center">{item.name}</td>
                  <td className="text-center">{item.price}€</td>
                  <td className="text-center">
                    <RemoveItemButton item_id={item.id} onRemove={handleRemove} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminPage;
