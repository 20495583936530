import React, { useState, useRef } from 'react';
import { IItem } from '../../constants/coffees';
import fetchItems from '../../helpers/fetchItems';
import loadingGIF from '../../images/loadingGIF.gif';

interface IProps {
  setItems: (React.Dispatch<React.SetStateAction<IItem[]>>);
  setFormModalOpen: (React.Dispatch<React.SetStateAction<boolean>>);
}

const AddItemForm = ({ setItems, setFormModalOpen }: IProps) => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState<string>('');
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [infoMessage, setInfoMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<{ name: string; short_name: string }[]>([]);
  const [optionName, setOptionName] = useState('');
  const [optionShortName, setOptionShortName] = useState('');

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  };

  const handleAddOption = () => {
    if (optionName && optionShortName) {
      setOptions([...options, { name: optionName, short_name: optionShortName }]);
      setOptionName('');
      setOptionShortName('');
    } else {
      console.log("Option fields cannot be empty");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (name && price !== '' && image) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/items`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            price: parseFloat(price),
            screen: 'upipe',
            options,
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const newItem = await response.json() as IItem;

        const formData = new FormData();
        formData.append('image', image);

        const secondResponse = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/items/${newItem.id}/set-image`, {
          method: 'POST',
          body: formData,
        });

        if (!secondResponse.ok) {
          throw new Error('Second network response was not ok');
        }

        fetchItems(setItems);

        setName('');
        setPrice('');
        setImage(null);
        setPreview(null);
        setOptions([]);

        setLoading(false);
        setFormModalOpen(false);
      } catch (error) {
        setInfoMessage("Chyba pri pridávaní itemu, niečo sa pokazilo. Stáva sa, keď sa zadá názov takého, ktorý už je vytvorený.");
        setLoading(false);
      }
    } else {
      setInfoMessage("Vyplňte všetky informácie");
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-100 min-h-screen w-full p-4">
  <div className="relative bg-white p-6 rounded-lg shadow-lg flex flex-col w-full max-w-5xl">
    {/* Close Button */}
    <button
      onClick={() => setFormModalOpen(false)}
      className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 text-xl"
    >
      ✖
    </button>
    <form onSubmit={handleSubmit} className="flex flex-col">


        <div className="mb-4 self-center">
            <div
              className="border border-gray-300 rounded-lg cursor-pointer mb-4 w-32 h-32 overflow-hidden flex items-center justify-center"
              onClick={() => fileInputRef.current?.click()}
            >
          {preview ? (
          <img 
            src={preview} 
            alt="Preview" 
            className="w-full h-full object-cover"
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
          ) : (
            <div className="w-full h-full flex items-center justify-center text-gray-400">
              Kliknite na výber obrázka
            </div>
          )}
        </div>

          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
            className="hidden"
          />
        </div>
        <h2 className="text-2xl font-bold mb-4 text-center">Pridať nový produkt</h2>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">
            Názov:
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-indigo-500"
            />
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">
            Cena:
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
              className="block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-indigo-500"
            />
          </label>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Možnosti({options.length})</h3>
          <div className="flex gap-2 mb-2 flex-wrap">

            <input
              type="text"
              value={optionName}
              onChange={(e) => setOptionName(e.target.value)}
              placeholder="Názov možnosti"
              className="flex-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-indigo-500"
            />
            <input
              type="text"
              value={optionShortName}
              onChange={(e) => setOptionShortName(e.target.value)}
              placeholder="Skrátený názov"
              className="flex-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-indigo-500"
            />
            <button
              type="button"
              onClick={handleAddOption}
              className="px-3 py-2 bg-green-500 text-white rounded-lg shadow hover:bg-green-700"
            >
              Pridať túto Možnosť
            </button>
          </div>
          <ul className="list-disc ml-5">
            {options.map((option, index) => (
              <li key={index} className="text-gray-700">
                {option.name} ({option.short_name})
              </li>
            ))}
          </ul>
        </div>
        
        
        <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg flex items-center justify-center">
          {loading ? <img src={loadingGIF} alt="Loading..." className="h-5 w-5"/> : "Pridať produkt"}
        </button>
        <div className="text-red-500">{infoMessage}</div>
      </form>
    </div>
    </div>
  );
};

export default AddItemForm;
